export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && !!window.sessionStorage.getItem("bp_id")

export const handleLogin = token => {
  window.sessionStorage.setItem("bp_id", token.Token)
  window.sessionStorage.setItem("username", token.username)
  return false
}

export const setServices = data => {
  if (typeof window !== `undefined`) {
    let servicesArr = data.map(service => service.name)
    servicesArr.map(service => {
      window.sessionStorage.setItem(`service-${service}`, `permitted`)
      return false
    })
  }
  return false
}

export const isLoggedIn = () => {
  const user = getUser()
  return !!user
}

export const logout = callback => {
  if (isBrowser()) window.sessionStorage.clear()
  callback()
}
