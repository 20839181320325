import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { isBrowser, isLoggedIn } from "@services/auth"

import "./style.scss"

const loggedIn = isLoggedIn()

const Header = ({ siteTitle }) => (
  <header>
    <div className="inner-wrap">
      <Link to="/">
        <img
          alt="Baypark SW logo"
          src="/images/global/header/bayparksw_logo.png"
        />
      </Link>
      <ul>
        <li>
          <Link to="/#About">About</Link>
        </li>
        <li>
          <Link to="/#Work">Work</Link>
        </li>
        <li>
          <Link to="/#Contact">Contact</Link>
        </li>
        <li>
          <Link to="/services/timecard">Services</Link>
        </li>
        {isBrowser && loggedIn && (
          <li>
            <Link to="/services/logout">Logout</Link>
          </li>
        )}
      </ul>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
