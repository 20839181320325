import { Link } from "gatsby"
import React from "react"

import "./style.scss"

const Footer = () => (
  <footer>
    <Link to="/">
      <img
        alt="Baypark SW logo"
        src="/images/global/header/bayparksw_logo.png"
      />
    </Link>
  </footer>
)

export default Footer
