import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"

import Header from "../header"
import Footer from "../footer"
import "./style.scss"
import "./services.scss"
import "./legacy_homepage.scss"

const Layout = ({ children }) => {
  return (
    <div className="layout-wrap">
      <Header siteTitle="Title" />
      <div className="main-wrap">
        <main>{children}</main>
        <Footer />
      </div>
    </div>
  )
}

export default Layout
