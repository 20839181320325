import React from "react"
import { Router } from "@reach/router"
import Layout from "@components/layout"
import PrivateRoute from "@components/privateRoute"
// import Login from "@components/_services/login"

const Validate = () => (
  <Layout>
    <Router>
      <PrivateRoute path="/validate" />
    </Router>
  </Layout>
)

export default Validate
