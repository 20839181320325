import React from "react"
import { navigate } from "gatsby"
import { isBrowser, isLoggedIn } from "@services/auth"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (isBrowser && !isLoggedIn() && location.pathname !== `/services/login`) {
    if (typeof window !== `undefined`) { 
      if(location.pathname === '/validate/') {
        console.log("Redirecting to garnishment site")
        window.location = "http://garnish.bayparksw.com"
      } else { 
        navigate("/services/login")
      }
    }
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
